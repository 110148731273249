import styled from "styled-components";
import tokens from "@company-mrv/mrv-design-system/tokens";
import { Accordion as BaseAccordion } from "components";
import { media } from "utils/styles/breakpoints";
import { Button } from "@company-mrv/mrv-design-system/components";
import { getThemePropertyValue } from "utils/pluginHelpers";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 504px;

  ${media.forTabletUp} {
    width: 440px;
  }

  ${media.forDesktopUp} {
    width: 504px;
  }
`;

export const About = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: ${tokens.spacingStackMedium};
`;

export const IllustrationContainer = styled.div`
  display: flex;
  position: relative;
  flex-grow: 1;
  width: 100%;
`;

export const Illustration = styled.img`
  width: 130px;
  justify-self: center;
  margin-left: auto;
  align-self: center;
`;

export const AboutTitle = styled.h3`
  margin: 0;
  font-size: ${tokens.fontSizeXSmall};
  color: ${tokens.colorNeutralXxdark};
  margin-bottom: ${tokens.spacingInlineMedium};
`;

export const AboutContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AboutDescription = styled.p`
  margin: 0;
  flex-grow: 2;
  color: ${tokens.colorNeutralXdark};
  font-size: ${tokens.fontSizeXxSmall};
  line-height: ${tokens.lineHeightMedium};
`;

export const DescriptionText = styled.p`
  margin: 0;
  margin-bottom: ${tokens.spacingStackMedium};
  color: ${tokens.colorNeutralXdark};
  font-size: ${tokens.fontSizeXxSmall};
  line-height: ${tokens.lineHeightMedium};

  &:last-child {
    margin-bottom: 20px;
  }
`;

export const Body = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  max-width: 504px;
`;

export const Accordion = styled(BaseAccordion)`
  margin-bottom: ${tokens.spacingStackXLarge};
`;

export const Bold = styled.span`
  color: ${props => props.color || tokens.colorNeutralXdark};
  line-height: ${tokens.lineHeightMedium};
  font-size: ${tokens.fontSizeXSmall};
  font-weight: ${tokens.fontWeightSemibold};
  margin-bottom: ${tokens.spacingStackMedium};
`;

export const ButtonCustom = styled(Button)`
  max-width: 162px;
  margin-bottom: 24px;
`;

export const HandHeldImage = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${tokens.spacingStackMedium};
`;

export const AccordionItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: ${tokens.spacingInlineXSmall};
`;

export const AccordionItemTitle = styled.span`
  margin-right: ${tokens.spacingStackMedium};
  font-weight: ${tokens.fontWeightSemibold};
  font-size: ${tokens.fontSizeSmall};
  color: ${tokens.colorNeutralXxdark};
  white-space: pre;
`;

export const Link = styled.a`
  font-weight: bold;
  color: ${tokens.colorBrandPrimaryPure};
`;

export const TitleContainer = styled.div`
  align-items: flex-start;
  display: flex;
  background-color: ${getThemePropertyValue(
    "construct.title.background.color",
    tokens.colorBackground01
  )};
  text-align: left;
  margin-bottom: 14px;
  margin-top: 24px;
`;

export const Title = styled.h1`
  margin: 0;
  color: ${tokens.colorNeutralXxdark};
  font-size: ${tokens.fontSizeSmall};
  line-height: ${tokens.lineHeightMedium};
  font-weight: ${tokens.fontWeightSemibold};
  width: 100%;
  display: inline-block;
`;

export const SubTitle = styled.span`
  color: ${tokens.colorBrandPrimaryPure};
  line-height: ${tokens.lineHeightMedium};
  font-size: ${tokens.fontSizeXSmall};
  font-weight: ${tokens.fontWeightSemibold};
  margin-bottom: 14px;
`;
