import React from "react";
import { GUIA_URL, ROUTES, STATUS_VA } from "utils/constants";
import * as Styled from "pages/milestones/components/pageContent/styles";
import { formatDate } from "../functions";
import { getThemePropertyValue } from "../pluginHelpers";

export const MILESTONE_STATUS = {
  COMING_SOON: 1,
  RELEASED: 2,
  COMPLETE: 3
};

export const MILESTONES_LOADING_STATUS = {
  LOADING: 1,
  LOADED: 2,
  ERROR: 3
};

export const MILESTONE_STATUS_DATA = {
  [MILESTONE_STATUS.COMING_SOON]: {
    text: "Em breve",
    color: "default"
  },
  [MILESTONE_STATUS.RELEASED]: {
    text: "Liberado",
    color: "orange"
  },
  [MILESTONE_STATUS.COMPLETE]: {
    text: "Concluído",
    color: "primary"
  }
};

export const MILESTONES = {
  VISITA: 0,
  VISTORIA: 1,
  ENTREGA: 2
};

export const MILESTONES_SECTIONS = {
  VISIT: "visita",
  INSPECTION: "vistoria",
  KEYS_DELIVERY: "entrega-de-chaves"
};

export const MILESTONES_ROUTES = [
  `${ROUTES.MILESTONES}/${MILESTONES_SECTIONS.VISIT}`,
  `${ROUTES.MILESTONES}/${MILESTONES_SECTIONS.INSPECTION}`,
  `${ROUTES.MILESTONES}/${MILESTONES_SECTIONS.KEYS_DELIVERY}`
];

export const MILESTONES_DATA = [
  {
    id: 1,
    title: getThemePropertyValue(
      "milestones.texts.VISITA_A_OBRA.title.text",
      "Visita à obra"
    ),
    titleGtm: "Visita a obra",
    text: milestone => {
      if (milestone.status === MILESTONE_STATUS.RELEASED) {
        const formatDate1 = formatDate(milestone?.details?.date, "DD/MM/YY");
        const formatDate2 = `${formatDate(milestone?.details?.date, "H")}h`;
        return (
          <>
            A visita está marcada para{" "}
            <b>
              {formatDate1}, às {formatDate2}.
            </b>{" "}
            Prepare-se para essa etapa!
          </>
        );
      }
      if (milestone.status === MILESTONE_STATUS.COMPLETE) {
        return getThemePropertyValue(
          "milestones.texts.VISITA_A_OBRA.complete.text",
          <>
            <b>Está etapa foi concluida.</b> Em breve, vamos te convidar para
            realizar a vistoria.
          </>
        );
      }
      return getThemePropertyValue(
        "milestones.texts.VISITA_A_OBRA.default.text",
        "Será liberada gradativamente quando a área comum atingir 70% de conclusão."
      );
    },
    link: milestone => {
      if (milestone.status === MILESTONE_STATUS.COMPLETE) {
        const isActive = getThemePropertyValue(
          "milestones.texts.VISTORIA_DO_IMOVEL.button.complete.link.active",
          undefined
        );
        if (isActive === false) {
          return null;
        }
        return ROUTES.TRACK_WORK;
      }
      return MILESTONES_ROUTES[MILESTONES.VISITA];
    },
    linkText: milestone => {
      if (milestone.status === MILESTONE_STATUS.RELEASED) {
        return "Me preparar para a visita";
      }
      if (milestone.status === MILESTONE_STATUS.COMPLETE) {
        return "Acompanhar evolução da obra";
      }
      return "Saber mais sobre a visita";
    },
    statusText: milestone => {
      if (milestone.status === MILESTONE_STATUS.RELEASED) {
        return "Agendado";
      }
      return MILESTONE_STATUS_DATA[milestone.status].text;
    }
  },
  {
    id: 2,
    title: getThemePropertyValue(
      "milestones.texts.VISTORIA_DO_IMOVEL.title.text",
      "Vistoria do imóvel"
    ),
    titleGtm: "Vistoria do imóvel",
    statusText: milestone => {
      if (milestone.status === MILESTONE_STATUS.RELEASED) {
        if (
          milestone.details.reason === STATUS_VA.VA_AGUARDANDO_AGENDAMENTO ||
          milestone.details.reason === STATUS_VA.VA_REPROVADA_REPARO_REALIZADO
        ) {
          return "Aguardando agendamento";
        }
        if (
          milestone.details.reason === STATUS_VA.VA_AGENDADA &&
          milestone.details.isReagendamento === false
        ) {
          return "Vistoria pendente";
        }
        if (
          milestone.details.reason === STATUS_VA.VA_AGENDADA &&
          milestone.details.isReagendamento === true
        ) {
          return "Revistoria pendente";
        }
        if (
          milestone.details.reason ===
            STATUS_VA.VA_AGUARDANDO_REAGENDAMENTO_CLIENTE_NAO_COMPARECEU ||
          milestone.details.reason ===
            STATUS_VA.VA_AGUARDANDO_REAGENDAMENTO_CLIENTE_SEM_DISPONIBILIDADE ||
          milestone.details.reason ===
            STATUS_VA.VA_AGUARDANDO_REAGENDAMENTO_SEM_CONTATO
        ) {
          return "Aguardando reagendamento";
        }
        if (
          milestone.details.reason ===
          STATUS_VA.VA_REPROVADA_REPARO_EM_ANDAMENTO
        ) {
          return "Aguardando reparo da unidade";
        }
      }
      return MILESTONE_STATUS_DATA[milestone.status].text;
    },
    text: (milestone, callback) => {
      if (milestone.status === MILESTONE_STATUS.COMPLETE) {
        return (
          <>
            <b>Vistoria concluída!</b> A próxima etapa da sua jornada é a
            entrega das chaves.
          </>
        );
      }
      if (milestone.status === MILESTONE_STATUS.RELEASED) {
        if (
          milestone.details.reason === STATUS_VA.VA_AGUARDANDO_AGENDAMENTO ||
          milestone.details.reason === STATUS_VA.VA_REPROVADA_REPARO_REALIZADO
        ) {
          return getThemePropertyValue(
            "milestones.texts.VISTORIA_DO_IMOVEL.released_VA_AGUARDANDO_AGENDAMENTO__VA_REPROVADA_REPARO_REALIZADO.text",
            <>
              <b>Agende a vistoria</b> do seu MRV e veja como analisar o imóvel
              com o guia.
            </>
          );
        }
        if (
          milestone.details.reason === STATUS_VA.VA_AGENDADA &&
          milestone.details.isReagendamento === false
        ) {
          return getThemePropertyValue(
            "milestones.texts.VISTORIA_DO_IMOVEL.released_VA_AGENDADA__isReagendamentoFalse.text",
            <>
              <b>Agendamento realizado!</b> Enquanto aguarda a vistoria, assista
              o nosso guia.
            </>
          );
        }
        if (
          milestone.details.reason === STATUS_VA.VA_AGENDADA &&
          milestone.details.isReagendamento === true
        ) {
          return getThemePropertyValue(
            "milestones.texts.VISTORIA_DO_IMOVEL.released_VA_AGENDADA__isReagendamentoTrue.text",
            <>
              <b>Nova vistoria agendada.</b> Se prepare para esse momento
              assistindo o{" "}
              <Styled.Link
                target="_blank"
                href={GUIA_URL}
                onClick={() => callback && callback("GUIA_FLAG")}
              >
                nosso guia.
              </Styled.Link>
            </>
          );
        }
        if (
          milestone.details.reason ===
            STATUS_VA.VA_AGUARDANDO_REAGENDAMENTO_CLIENTE_NAO_COMPARECEU ||
          milestone.details.reason ===
            STATUS_VA.VA_AGUARDANDO_REAGENDAMENTO_CLIENTE_SEM_DISPONIBILIDADE ||
          milestone.details.reason ===
            STATUS_VA.VA_AGUARDANDO_REAGENDAMENTO_SEM_CONTATO
        ) {
          return getThemePropertyValue(
            "milestones.texts.VISTORIA_DO_IMOVEL.released_VA_AGUARDANDO_REAGENDAMENTO_CLIENTE_NAO_COMPARECEU__VA_AGUARDANDO_REAGENDAMENTO_CLIENTE_SEM_DISPONIBILIDADE__VA_AGUARDANDO_REAGENDAMENTO_SEM_CONTATO.text",
            <>
              <b>Reagende</b> a vistoria do seu MRV.
            </>
          );
        }
        if (
          milestone.details.reason ===
          STATUS_VA.VA_REPROVADA_REPARO_EM_ANDAMENTO
        ) {
          return getThemePropertyValue(
            "milestones.texts.VISTORIA_DO_IMOVEL.released_VA_REPROVADA_REPARO_EM_ANDAMENTO.text",
            <>
              <b>Estamos analisando</b> suas considerações. Em breve, vamos
              liberar o agendamento.
            </>
          );
        }
        if (milestone.details.reason === null) {
          return getThemePropertyValue(
            "milestones.texts.VISTORIA_DO_IMOVEL.default.text",
            <>
              O seu empreendimento foi liberado! Estamos finalizando o seu MRV
              para a vistoria, aguarde.
            </>
          );
        }
      }
      return getThemePropertyValue(
        "milestones.texts.VISTORIA_DO_IMOVEL.default.text",
        "Nesta etapa, você vai conhecer e vistoriar o seu MRV."
      );
    },
    linkText: milestone => {
      if (
        milestone.status === MILESTONE_STATUS.COMING_SOON ||
        milestone.details.reason === null
      ) {
        return "Guia da Vistoria";
      }
      if (milestone.status === MILESTONE_STATUS.RELEASED) {
        if (
          milestone.details.reason === STATUS_VA.VA_AGUARDANDO_AGENDAMENTO ||
          milestone.details.reason === STATUS_VA.VA_REPROVADA_REPARO_REALIZADO
        ) {
          return "Agendar vistoria";
        }
        if (
          milestone.details.reason === STATUS_VA.VA_AGENDADA &&
          milestone.details.isReagendamento === false
        ) {
          return "Consultar data ou reagendar";
        }
        if (
          milestone.details.reason === STATUS_VA.VA_AGENDADA &&
          milestone.details.isReagendamento === true
        ) {
          return "Consultar data ou reagendar";
        }
        if (
          milestone.details.reason ===
            STATUS_VA.VA_AGUARDANDO_REAGENDAMENTO_CLIENTE_NAO_COMPARECEU ||
          milestone.details.reason ===
            STATUS_VA.VA_AGUARDANDO_REAGENDAMENTO_CLIENTE_SEM_DISPONIBILIDADE ||
          milestone.details.reason ===
            STATUS_VA.VA_AGUARDANDO_REAGENDAMENTO_SEM_CONTATO
        ) {
          return "Reagendar vistoria";
        }
      }
      return null;
    },
    link: milestone => {
      if (
        milestone.status === MILESTONE_STATUS.COMING_SOON ||
        milestone.details.reason === null
      ) {
        const isActive = getThemePropertyValue(
          "milestones.texts.VISTORIA_DO_IMOVEL.button.comingSoon.link.active",
          undefined
        );
        if (isActive === false) {
          return undefined;
        }

        return GUIA_URL;
      }
      if (milestone.status === MILESTONE_STATUS.COMPLETE) {
        return null;
      }
      if (milestone.status === MILESTONE_STATUS.RELEASED) {
        if (
          milestone.details.reason === STATUS_VA.VA_AGUARDANDO_AGENDAMENTO ||
          milestone.details.reason === STATUS_VA.VA_REPROVADA_REPARO_REALIZADO
        ) {
          return {
            type: "CALL_MIA"
          };
        }
        if (
          milestone.details.reason === STATUS_VA.VA_AGENDADA &&
          milestone.details.isReagendamento === false
        ) {
          return {
            type: "CALL_MIA"
          };
        }
        if (
          milestone.details.reason === STATUS_VA.VA_AGENDADA &&
          milestone.details.isReagendamento === true
        ) {
          return {
            type: "CALL_MIA"
          };
        }
        if (
          milestone.details.reason ===
            STATUS_VA.VA_AGUARDANDO_REAGENDAMENTO_CLIENTE_NAO_COMPARECEU ||
          milestone.details.reason ===
            STATUS_VA.VA_AGUARDANDO_REAGENDAMENTO_CLIENTE_SEM_DISPONIBILIDADE ||
          milestone.details.reason ===
            STATUS_VA.VA_AGUARDANDO_REAGENDAMENTO_SEM_CONTATO
        ) {
          return {
            type: "CALL_MIA"
          };
        }
        if (
          milestone.details.reason ===
          STATUS_VA.VA_REPROVADA_REPARO_EM_ANDAMENTO
        ) {
          return null;
        }
      }
      return MILESTONES_ROUTES[MILESTONES.VISTORIA];
    },
    linkTextSecondary: milestone => {
      if (milestone.status === MILESTONE_STATUS.RELEASED) {
        if (
          milestone.details.reason === STATUS_VA.VA_AGUARDANDO_AGENDAMENTO ||
          milestone.details.reason === STATUS_VA.VA_REPROVADA_REPARO_REALIZADO
        ) {
          return "Guia da Vistoria";
        }
        if (
          milestone.details.reason === STATUS_VA.VA_AGENDADA &&
          milestone.details.isReagendamento === false
        ) {
          return "Guia da Vistoria";
        }
        if (
          milestone.details.reason === STATUS_VA.VA_AGENDADA &&
          milestone.details.isReagendamento === true
        ) {
          return "Baixar o laudo de reprovação";
        }
      }
      return MILESTONE_STATUS_DATA[milestone.status].text;
    },
    linkSecondary: milestone => {
      const isActive = getThemePropertyValue(
        "milestones.texts.VISTORIA_DO_IMOVEL.button.released.linkSecondary.active",
        undefined
      );
      if (isActive === false) {
        return undefined;
      }

      if (milestone.status === MILESTONE_STATUS.RELEASED) {
        if (
          milestone.details.reason === STATUS_VA.VA_AGUARDANDO_AGENDAMENTO ||
          milestone.details.reason === STATUS_VA.VA_REPROVADA_REPARO_REALIZADO
        ) {
          return GUIA_URL;
        }
        if (
          milestone.details.reason === STATUS_VA.VA_AGENDADA &&
          milestone.details.isReagendamento === false
        ) {
          return GUIA_URL;
        }
        if (
          milestone.details.reason === STATUS_VA.VA_AGENDADA &&
          milestone.details.isReagendamento === true
        ) {
          return undefined;
        }
      }
      return undefined;
    },
    directionButtons: milestone => {
      if (milestone.status === MILESTONE_STATUS.RELEASED) {
        if (
          milestone.details.reason === STATUS_VA.VA_AGUARDANDO_AGENDAMENTO ||
          milestone.details.reason === STATUS_VA.VA_REPROVADA_REPARO_REALIZADO
        ) {
          return "row";
        }
      }
      return undefined;
    }
  },
  {
    MILESTONES_ROUTES,
    id: 3,
    title: "Entrega de chaves",
    titleGtm: "Entrega de chaves",
    text:
      "Prepare-se para abrir as portas da sua nova vida. Aguarde nosso contato.",
    link: MILESTONES_ROUTES[MILESTONES.ENTREGA]
  }
];
