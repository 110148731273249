import styled from "styled-components";
import tokens from "@company-mrv/mrv-design-system/tokens";
import { GTMBase } from "components";
import { media } from "utils/styles/breakpoints";
import { ArrowBack } from "@company-mrv/mrv-design-system/icons";
import { getThemePropertyValue } from "utils/pluginHelpers";

export const Background = styled.div`
  min-width: 100%;
  background-color: ${props => props.theme.BrandTokens.NeutralColors.XxLight};
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ButtonBack = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  background: ${tokens.colorBackground04};
`;

export const ButtonContainer = styled(GTMBase)`
  cursor: pointer;
  border: ${tokens.borderRadiusNone};
  height: 48px;
  display: flex;
  flex-direction: row;
  background: ${tokens.colorBackground04};
  width: ${props => (props.mobileBreak ? props.mobileBreak : "312px")};
  padding-right: 24px;
  padding-left: 24px;

  ${media.forTabletUp} {
    width: 440px;
    padding: 0;
  }

  ${media.forDesktopUp} {
    width: 504px;
  }
`;

export const ButtonBackIcon = styled(ArrowBack).attrs({
  color: getThemePropertyValue(
    "buttonBack.color",
    tokens.colorBrandSecondaryPure
  ),
  size: 15
})`
  margin: 16.41px 8.21px 16.41px 0px;
`;

export const ButtonBackLabel = styled.p`
  margin-top: 11px;
  margin-bottom: 12px;
  font-family: ${tokens.fontFamily01};
  font-weight: ${tokens.fontWeightSemibold};
  font-size: ${tokens.fontSizeXxSmall};
  line-height: ${tokens.lineHeightDefault};
  color: ${tokens.colorNeutralDark};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${props => (props.mobileBreak ? props.mobileBreak : "312px")};

  ${media.forTabletUp} {
    width: 440px;
  }

  ${media.forDesktopUp} {
    width: 504px;
  }

  max-width: ${props =>
    props.mobileBreakMax ? props.mobileBreakMax : "initial"};
`;
